import React from 'react';
import './HomepageV2.css';
import { Github, Linkedin, Twitter, Music, X, Music2, Music2Icon, Music3, Music4 } from 'lucide-react';
import WorkSection from './sections/WorkSection';

const HomepageV2 = () => {
  return (
    <div className="App">
      <main>
        <h1>
          <span>*</span>
          <a href="/">.nbayindirli<span>.</span>com</a>
        </h1>
        <p className="disclaimer">enhanced descriptions & photography coming soon</p>

        <section>
          <p>hi, i'm Noah</p>
          <p>i spend my time making life safer and easier for humans through the use of software, hardware, machine intelligence, and decentralized engineering</p>
          <hr></hr>
          <p>i currently work on <a href="https://hyperlane.xyz/" target="_blank" rel="noopener noreferrer">Hyperlane</a> & <a href="https://www.primeprotocol.xyz/" target="_blank" rel="noopener noreferrer">Prime Protocol</a> as a senior blockchain and founding engineer</p>
          <p>i previously worked on <a href="https://www.youtube.com/watch?v=NrmMk1Myrxc" target="_blank" rel="noopener noreferrer">Amazon Go</a>, <a href="https://www.youtube.com/watch?v=xH_SVNVIfzk" target="_blank" rel="noopener noreferrer">Amazon One</a>, & <a href="https://alexa.amazon.com/" target="_blank" rel="noopener noreferrer">Alexa</a> as a senior fullstack software engineer</p>
          <p>my technical passions lie with decentralized engineering and machine intelligence</p>
          <p>otherwise, i spend my time skiing, climbing, hiking, and playing guitar</p>
        </section>

        <blockquote>
          "Any sufficiently advanced technology is indistinguishable from magic."
          <footer>- Arthur C. Clarke</footer>
        </blockquote>

        <div>
            <WorkSection />
        </div>

        <section>
          <h2>my links</h2>
          <div className="social-links">
            <a href="https://github.com/nbayindirli" target="_blank" rel="noopener noreferrer"><Github size={30} /></a>
            <a href="https://www.linkedin.com/in/noahbay/" target="_blank" rel="noopener noreferrer"><Linkedin size={30} /></a>
            <a href="https://x.com/Noah_Bayindirli" target="_blank" rel="noopener noreferrer"><Twitter size={30} /></a>
            <a href="https://open.spotify.com/user/1222775567?si=a70f3a9493cb4462" target="_blank" rel="noopener noreferrer"><Music2 size={30} /></a>
          </div>
        </section>
      </main>
    </div>
  );
};

export default HomepageV2;